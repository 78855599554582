import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CustomPageTemplate } from "@iamilyas/store-template-library";
import { useNavigate, useParams } from "react-router-dom";
import useConfig from "src/hooks/useConfig";
import { isEmpty } from "lodash";
import {
  handleGetPageByName,
  handleGetNavigationById,
  handleGetCompleteStoreInformation,
  handleCreateCustomerByEmail,
  getNavigationLinkByIds,
} from "src/service/host";
import { buildNavigationPath, PATH_PAGE } from "src/routes/paths";
import { cacheCallback } from "src/utils/requests";
import { getCartCount } from "src/utils/cart";
import GoogleAnalyticsTrackPage from "src/components/GoogleAnalyticsTrackPage";
import { updateCurency } from "src/redux/slices/store";
import { chooseCurrency, filterCartByCurrency } from "src/utils/currency";
import {
  clearCart,
  clearRecentlyViewedProducts,
} from "src/redux/slices/product";
import { DEFAULT_NAVIGATION_LINK } from "src/utils/defaults";
import { useDispatch, useSelector } from "../redux/store";

export default function CustomPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { config } = useConfig();

  // STATE
  const { cart: cartStore } = useSelector((state) => state.product);
  const {
    currency: prevSelectedCurrency,
    options: { currencies },
    loading: { currencies: currenciesLoading },
  } = useSelector((state) => state.store);
  const capability = config?.capability;
  const storeCurrency = config?.currency;
  const currency = chooseCurrency(
    prevSelectedCurrency,
    storeCurrency,
    capability,
    currencies
  );
  const cart = filterCartByCurrency(currency, cartStore);

  // DATA
  const { name } = useParams();
  const page = useRef(null);
  const cartTotal = getCartCount(cart);

  // LOADING
  const [loading, setLoading] = useState(false);

  // CACHE
  const navigations = useRef([]);
  const storeInformation = useRef([]);

  const handleGetPage = useCallback(() => {
    if (!loading && !page.current) {
      setLoading(true);
      handleGetPageByName(name)
        .then((resp) => {
          if (!resp.data) {
            // If no data navigate to 404
            throw new Error("No data found");
          }
          page.current = resp.data;
          setLoading(false);
        })
        .catch(() => navigate(PATH_PAGE.page404));
    }
  }, [loading, name, navigate]);

  useEffect(() => {
    handleGetPage();
  }, [handleGetPage]);

  const handleGetNavigation = useCallback(
    async (id) => {
      if (!id) {
        return new Promise((res) => {
          res(DEFAULT_NAVIGATION_LINK);
        });
      }
      return cacheCallback(navigations.current, id, handleGetNavigationById);
    },
    [navigations]
  );

  const handleGetNavigations = useCallback(async (ids) => {
    if (!ids || isEmpty(ids)) {
      return new Promise((res) => {
        res([
          { ...DEFAULT_NAVIGATION_LINK, id: 1 },
          { ...DEFAULT_NAVIGATION_LINK, id: 2 },
        ]);
      });
    }
    return getNavigationLinkByIds(ids);
  }, []);

  const handleGetStoreInformation = useCallback(async () => {
    return cacheCallback(
      storeInformation.current,
      "store",
      handleGetCompleteStoreInformation
    );
  }, []);

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        } else {
          navigate(PATH_PAGE.page404);
        }
      }
    },
    [navigate]
  );

  const handleChangeCurrency = useCallback(
    (value) => {
      dispatch(clearCart());
      dispatch(clearRecentlyViewedProducts());
      dispatch(updateCurency(value));
    },
    [dispatch]
  );

  const handleCreateCustomer = useCallback(
    (email) => handleCreateCustomerByEmail(email),
    []
  );

  const CustomPageMemo = useMemo(() => {
    const theme = config?.theme;
    const logo = config?.assets;
    const isViewReady = Boolean(theme && !loading && page.current);

    return (
      isViewReady && (
        <CustomPageTemplate
          context={{ capability, pagePath: name }}
          currency={currency}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          logo={logo}
          config={theme}
          cartTotal={cartTotal}
          page={page.current}
          handleNavigationClick={handleNavigationClick}
          handleChangeCurrency={handleChangeCurrency}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        />
      )
    );
  }, [
    config,
    cartTotal,
    name,
    loading,
    currency,
    currencies,
    currenciesLoading,
    capability,
    handleGetNavigation,
    handleGetNavigations,
    handleGetStoreInformation,
    handleNavigationClick,
    handleChangeCurrency,
    handleCreateCustomer,
  ]);

  return <GoogleAnalyticsTrackPage>{CustomPageMemo}</GoogleAnalyticsTrackPage>;
}
